import React from "react";

import {RouterLink} from "../RouterLink";
import "./Menu.css";

export const Menu = () => (
    <div className="menu">
        <RouterLink link="/domu/" text="Domů" />
        <RouterLink link="/fotografie/" text="Fotografie" />
        <RouterLink link="/cenik/" text="Ceník" />
        <RouterLink link="/kontakt/" text="Kontakt" />
    </div>
);
