import React from "react";
import {NavLink} from "react-router-dom";

import "./RouterLink.css";

export const RouterLink = ({link, text}) => (
    <NavLink to={link} activeClassName="active">
        <div className="router-link">
            {text}
        </div>
    </NavLink>
);
