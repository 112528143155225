import React from "react";

import "./Photo.css";

export const Photo = () => (
    <React.Fragment>
        {[...new Array(15).keys()].map((item) => (
            <a style={{display: "inline-block"}} href={`../photos/${item + 1}.jpg`}>
                <img className="small-photo" src={`../photos/thumbnail/tn_${item + 1}.jpg`} alt="Fotografie"/>
            </a>
        ))}
    </React.Fragment>
);
