import React from "react";

import "./Contact.css";

export const Contact = () => (
    <React.Fragment>
        <a href="https://mapy.cz/s/1gshP" target="_blank" rel="noopener noreferrer">
            <div className="contact-map" />
        </a>
        <table className="contact-table">
            <tr>
                <td>Adresa</td>
                <td>Pavel Pospíšil</td>
            </tr>
            <tr>
                <td></td>
                <td>Tasov 58</td>
            </tr>
            <tr>
                <td></td>
                <td>675 79</td>
            </tr>
            <tr className="cell-top-padding">
                <td>Telefon</td>
                <td>+420 775 363 633</td>
            </tr>
            <tr>
                <td>E-mail</td>
                <td><a href="mailto:pajdatas@seznam.cz?subject=Dotaz z webu">pajdatas@seznam.cz</a></td>
            </tr>
        </table>
    </React.Fragment>
);
