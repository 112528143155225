import React from "react";

export const Price = () => (
    <React.Fragment>
        Cena za&nbsp;jehně je&nbsp;70&nbsp;Kč za&nbsp;kilogram v&nbsp;živé váze,
        což je&nbsp;kolem 2 000&nbsp;Kč za&nbsp;kus. Na&nbsp;podzim bývají
        na&nbsp;prodej starší bahnice vhodné na&nbsp;výrobu klobás, cena kolem
        1 500&nbsp;Kč za bahnici podle kondice.
    </React.Fragment>
);
