import React from "react";

export const Home = () => (
    <React.Fragment>
        Bahnice našeho stáda jsou kříženky masných plemen, nejčastěji texel
        a&nbsp;suffolk, berani čistokrevní jedinci plemen suffolk a&nbsp;texel.
        Většina jehňat je&nbsp;určena k&nbsp;prodeji, nejlepší jehnice necháváme
        na&nbsp;obnovu stáda. Jehňata jsou na&nbsp;prodej na&nbsp;podzim. Přes
        zimu krmíme senem, po&nbsp;většinu roku paseme na&nbsp;vlastních
        i&nbsp;pronajatých pozemcích. Chov splňuje požadavky biozemědělství.
    </React.Fragment>
);
