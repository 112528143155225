import React from "react";
import PropTypes from "prop-types";

import "./Link.css";

export const Link = ({url, newTab, children}) => (
    url ? (
        <a className="link active" href={url} target={newTab && "_blank"} rel={newTab && "noopener noreferrer"}>
            {children}
        </a>
    ) : (
        <span className="link">
            {children}
        </span>
    )
);

Link.propTypes = {
    url: PropTypes.string,
    newTab: PropTypes.bool,
};

Link.defaultProps = {
    url: undefined,
    newTab: false,
};
