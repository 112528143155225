import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {Menu, Footer} from "./components";
import {Contact, Home, Photo, Price} from "./screens";
import "./styles.css";

export const App = () => (
    <>
        <div className="center-page">
            <div className="bottom-shadow-div">
                <Router>
                    <>
                        <Menu />
                        <div className="content-div">
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/domu/" exact component={Home} />
                                <Route path="/fotografie/" exact component={Photo} />
                                <Route path="/cenik/" exact component={Price} />
                                <Route path="/kontakt/" exact component={Contact} />
                                <Route component={Home} />
                            </Switch>
                        </div>
                    </>
                </Router>
            </div>
        </div>
        <Footer />
    </>
);
